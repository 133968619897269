// import { HorizontalTabs } from "./components/HorizontalTabs/HorizontalTabs.component";
// import { Offcanvas } from "./components";
// import { DepositForm } from "./components/DepositForm.page";
// import { Packages } from "./components/Packages.page";
// import { WithdrawalForm } from "./components/WithdrawalForm.page";

// export const CashierPage = ({ showReportPage, onHide }) => {
// 	const items = [
// 		{
// 			id: 0,
// 			title: "Depositar",
// 			component: <DepositForm onHide={onHide} />,
// 		},
// 		{
// 			id: 1,
// 			title: "Retirar",
// 			component: <WithdrawalForm onHide={onHide} />,
// 		},
// 		{
// 			id: 2,
// 			title: "Paquetes",
// 			component: <Packages onHide={onHide} />,
// 		},
// 	];
// 	return (
// 		<>
// 			<Offcanvas onHide={onHide} title="Cajero" showState={showReportPage} variant="md">
// 				<HorizontalTabs items={items} defaultValue={2} />
// 			</Offcanvas>
// 		</>
// 	);
// };

import { HorizontalTabs } from "./components/HorizontalTabs/HorizontalTabs.component";
import { Offcanvas } from "./components";
import { Packages } from "./components/Packages.page";

export const CashierPage = ({ showReportPage, onHide }) => {
	// Mantén solo la pestaña "Paquetes"
	const items = [
		{
			id: 0,
			title: "Paquetes",
			component: <Packages onHide={onHide} />,
		},
	];

	return (
		<>
			<Offcanvas onHide={onHide} title="Cajero" showState={showReportPage} variant="md">
				<HorizontalTabs items={items} defaultValue={0} />
			</Offcanvas>
		</>
	);
};
