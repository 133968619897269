import { HorizontalTabs, Offcanvas, AccountsPage } from "./components";
import IBPage from "./components/IBPage.page";
import UserProfileForm from "./components/UserForm.page";

export const UserProfilePage = ({ showReportPage, onHide, isIB }) => {
	const items = [
		{
			id: 0,
			title: "Usuario",
			component: <UserProfileForm onHide={onHide} />,
		},
		{
			id: 1,
			title: "Cuentas",
			component: <AccountsPage onHide={onHide} />,
		},
	];

	if (isIB) {
		items.push({
			id: 2,
			title: "IB",
			component: <IBPage onHide={onHide} />,
		});
	}

	return (
		<>
			<Offcanvas onHide={onHide} title="Usuario" showState={showReportPage} variant={"md"}>
				<HorizontalTabs items={items} />
			</Offcanvas>
		</>
	);
};
