import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

export function WithdrawalsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const initialData = [{ amount: "-", service: "-", createdAt: "-", status: "-" }];
	const [reports, setReports] = useState([]);
	const [loading, setLoading] = useState(true);
	const columns = [
		{ field: "id", headerName: "ID", minWidth: 90, flex: 0.5 },
		{ field: "amount", headerName: "Cantidad", minWidth: 110, flex: 0.5 },
		{ field: "currency", headerName: "Moneda", minWidth: 110, flex: 0.5 },
		{ field: "type", headerName: "Transacción", minWidth: 110, flex: 0.5 },
		{
			field: "createdAt",
			headerName: "Fecha",
			minWidth: 110,
			width: 200,
			sortable: false,
			renderCell: (params) => {
				if (!params.value) {
					return <Box>-</Box>; // Manejar valores nulos
				}
				let [createdAt, time] = params.value.split("T");
				time = time ? time.slice(0, -1) : ""; // Verificación adicional
				return (
					<Box>
						<div>
							{createdAt} {time}
						</div>
					</Box>
				);
			},
		},
	];

	useEffect(() => {
		findExtract();
	}, []);

	const findExtract = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const body = {
			accountId: selectedAccount.accountId || null,
		};
		try {
			const response = await axios.post("https://api.nexgenbroker.net:5000/extract", body, config);

			if (response.status === 200) {
				setLoading(false);
				setReports(response.data);
			} else {
				toast.error("Ocurrio un Errorr al obtener los reportes.");
			}
		} catch (error) {
			toast.error("Ocurrio un Errorr al obtener los reportes.");
		}
	};

	return (
		<DataGrid
			columnHeaderHeight={30}
			rowHeight={30}
			sx={{
				maxHeight: `${window.innerHeight * 0.18}px`,
				"& .MuiDataGrid-footerContainer": {
					height: "30px",
					minHeight: "30px",
				},
			}}
			rows={reports}
			getRowId={(row) => `${row.id}${row.createdAt}`}
			columns={columns.map((column) => ({
				...column,
				renderHeader: () => <strong>{column.headerName}</strong>,
			}))}
			loading={loading}
		/>
	);
}
