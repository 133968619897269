import PropTypes from "prop-types";

import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef, useState } from "react";
import { widget } from "../../charting_library";
import Datafeed from "./datafeed.js";
import "./index.css";
import { ReportPage } from "./pages/Report.page.jsx";
import { CashierPage } from "./pages/Cashier.page.jsx";
import { UserProfilePage } from "./pages/UserProfile.page.jsx";
import { store } from "../../store/store.js";
import { Offcanvas } from "react-bootstrap";
import { OpeningPositionsTable } from "./pages/components/OpenPositionsTable.jsx";
import { WithdrawalsTable } from "./pages/components/WithdrawalsTable.jsx";
import { HistoryPositionsTable } from "./pages/components/HistoryPositionsTable.jsx";
import { WatchList } from "./pages/components/WatchList.jsx";
import { Box, Tab, Tabs } from "@mui/material";

import { MyDraggableComponent } from "../../components/TVChartContainer/pages/components/Draggable/Draggable.component.jsx";

var profileIconWithIB = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" stroke-width="1">
  <!-- Letras IB -->
  <text x="8" y="20" fill="white" font-size="16" font-family="Arial">IB</text>
</svg>`;

var userIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="none" stroke="white" stroke-width="1">
  <!-- Cabeza -->
  <path d="M12 2c-2.21 0-4 1.79-4 4v2c0 2.21 1.79 4 4 4s4-1.79 4-4V6c0-2.21-1.79-4-4-4z"></path>
  
  <!-- Ojos -->
  <circle cx="10" cy="6" r="0.5" fill="white"></circle>
  <circle cx="14" cy="6" r="0.5" fill="white"></circle>
  
  <!-- Boca feliz -->
  <path d="M10 1c1 1.5 3 1.5 4 0" fill="none" stroke="white" stroke-width="1"></path>
  
  <!-- Cuerpo -->
  <path d="M12 14c-3 0-9 1-9 3v2h18v-2c0-2-3-3-6-3z" fill="none" stroke="white" stroke-width="1"></path>
</svg>`;

var positionsIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" stroke-width="1">
  <!-- Checkmark -->
  <path d="M4 6l2 2 4-4"></path>
  <!-- Rectangular box with dots -->
  <rect x="12" y="5" width="8" height="2" rx="1" ry="1"></rect>
  <!-- Line inside the box -->
  <line x1="15" y1="6" x2="19" y2="6"></line>
  
  <!-- Checkmark -->
  <path d="M4 12l2 2 4-4"></path>
  <!-- Rectangular box -->
  <rect x="12" y="11" width="8" height="2" rx="1" ry="1"></rect>
  
  <!-- Cross -->
  <path d="M5 16l4 4"></path>
  <path d="M5 20l4 -4"></path>  <!-- Rectangular box with dots -->
  <rect x="12" y="17" width="8" height="2" rx="1" ry="1"></rect>
  <!-- Line inside the box -->
  <line x1="15" y1="18" x2="19" y2="18"></line>
</svg>`;

var symbolsIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" fill="none" stroke="white" stroke-width="1.5">
  <!-- First item with circle -->
  <circle cx="6" cy="6" r="2" stroke="white" stroke-width="1.5"></circle>
  <!-- Rectangular box with rounded corners and gradient -->
  <rect x="10" y="5" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad1)"></rect>
  
  <!-- Second item with circle -->
  <circle cx="6" cy="12" r="2" stroke="white" stroke-width="1.5"></circle>
  <!-- Rectangular box with rounded corners and gradient -->
  <rect x="10" y="11" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad2)"></rect>
  
  <!-- Third item with circle -->
  <circle cx="6" cy="18" r="2" stroke="white" stroke-width="1.5"></circle>
  <!-- Rectangular box with rounded corners and gradient -->
  <rect x="10" y="17" width="10" height="3" rx="1.5" ry="1.5" stroke="white" fill="url(#grad3)"></rect>

  <!-- Definitions for gradients -->
  <defs>
    <linearGradient id="grad1" x1="0" x2="1" y1="0" y2="0">
      <stop offset="0%" stop-color="#4caf50" />
      <stop offset="100%" stop-color="#81c784" />
    </linearGradient>
    <linearGradient id="grad2" x1="0" x2="1" y1="0" y2="0">
     <stop offset="0%" stop-color="#4caf50" />
      <stop offset="100%" stop-color="#81c784" />
    </linearGradient>
    <linearGradient id="grad3" x1="0" x2="1" y1="0" y2="0">
     <stop offset="0%" stop-color="#4caf50" />
      <stop offset="100%" stop-color="#81c784" />
    </linearGradient>
  </defs>
</svg>

`;

var cashierIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25" height="25" fill="none" stroke="white" stroke-width="1">
  <rect x="3" y="2" width="18" height="12" rx="2" ry="2"></rect>
  <line x1="6" y1="5" x2="10" y2="5"></line>
  <line x1="6" y1="7" x2="10" y2="7"></line>
  <line x1="12" y1="5" x2="14" y2="5"></line>
  <line x1="6" y1="9" x2="10" y2="9"></line>
  <line x1="12" y1="7" x2="14" y2="7"></line>
  <rect x="3" y="14" width="18" height="8" rx="2" ry="2"></rect>
  <line x1="12" y1="16" x2="12" y2="20"></line>
  <circle cx="12" cy="17" r="1"></circle>
  <path d="M9 16h6v1H9z"></path>
  <path d="M8 18h8v1H8z"></path>
</svg>
`;

let activePosition = false;
let activeWatchSymbol = false;

export const TVChartContainer = () => {
	const chartContainerRef = useRef();
	const user = JSON.parse(localStorage.getItem("user"));
	const [showCashierPage, setShowCashierPage] = useState(false);
	const [showUserProfilePage, setShowUserProfilePage] = useState(false);
	const [showPosition, setShowPosition] = useState(false);
	const [showWatchList, setShowWatchList] = useState(false);
	const [value, setValue] = useState(0);
	const items = [
		{
			id: 0,
			title: "Posiciones abiertas",
			component: <OpeningPositionsTable />,
		},
		{
			id: 1,
			title: "Extracto",
			component: <WithdrawalsTable />,
		},
		{
			id: 2,
			title: "Historial de posiciones",
			component: <HistoryPositionsTable />,
		},
	];

	const onChange = () => {
		const chartIframe = document.querySelector("iframe");
		if (activePosition) {
			setShowPosition(false);
			activePosition = false;
			const newHeight = window.innerHeight;
			chartIframe.style.height = `${newHeight}px`;
		} else {
			setShowPosition(true);
			activePosition = true;
			const newHeight = window.innerHeight * 0.75;
			chartIframe.style.height = `${newHeight}px`;
		}
	};

	const openSymbols = () => {
		const chartIframe = document.querySelector("iframe");
		if (activeWatchSymbol) {
			setShowWatchList(false);
			activeWatchSymbol = false;
			store.getState().setWatchList(false);
		} else {
			setShowWatchList(true);
			activeWatchSymbol = true;
			store.getState().setWatchList(true);
		}
	};

	const getLastSymbol = async () => {
		const symbolInfo = await localStorage.getItem("symbolInfo");
		const period = await localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution");
		if (symbolInfo) {
			const parsedSymbolInfo = JSON.parse(symbolInfo);
			return { symbol: parsedSymbolInfo.displayName, period: period };
		}
		return null;
	};

	useEffect(() => {
		const start = async () => {
			let lastData = await getLastSymbol();
			if (lastData === null) {
				lastData = {};
				lastData.symbol = "Step 100 Index";
				lastData.period = "1";
			}
			if (lastData) {
				startChart(lastData);
			}
		};
		start();
	}, []);

	const startChart = (lastData) => {
		if (!lastData) return;
		const chart = {
			theme: "dark",
			locale: "es",
			symbol: lastData.symbol,
			interval: lastData.period,
			height: `${window.innerHeight}px`,
			width: "100%",
			container: chartContainerRef.current,
			datafeed: Datafeed,
			library_path: "../../charting_library/",
			time_frames: [
				{ text: "1d", resolution: "1", description: "1 Dia" },
				{ text: "3d", resolution: "5", description: "3 Dias" },
				{ text: "3d", resolution: "15", description: "3 Dias" },
				{ text: "5d", resolution: "60", description: "5 Dias" },
				{ text: "7d", resolution: "240", description: "7 Dias" },
			],
			disabled_features: ["header_symbol_search"],
		};

		const tvWidget = new widget(chart);

		tvWidget.onChartReady(() => {
			tvWidget.chart().getSeries().setChartStyleProperties(1, {
				upColor: "hotPink",
				downColor: "dodgerBlue",
				borderUpColor: "hotPink",
				borderDownColor: "dodgerBlue",
				wickUpColor: "hotPink",
				wickDownColor: "dodgerBlue",
			});

			tvWidget.setCSSCustomProperty("--my-theme-color", "#123AAA");
			tvWidget.setCSSCustomProperty("--tv-toolbar-icon-color", "#FF4500"); // Example to change toolbar icon color
			tvWidget.setCSSCustomProperty("--tv-background-color", "#1E1E1E");

			const setChart = store.getState().setChart;
			setChart(tvWidget);

			tvWidget.headerReady().then(() => {
				const buttonPositions = tvWidget.createButton({
					useTradingViewStyle: false,
					align: "left",
				});
				buttonPositions.innerHTML = cashierIcon;
				buttonPositions.appendChild(document.createTextNode(" Cajero"));
				buttonPositions.setAttribute("title", " Cajero");
				buttonPositions.style.display = "flex";
				buttonPositions.style.alignItems = "center";
				buttonPositions.style.gap = "5px";
				buttonPositions.addEventListener("click", function () {
					setShowCashierPage(true);
				});

				const buttonCashier = tvWidget.createButton({
					useTradingViewStyle: false,
					align: "left",
				});
				buttonCashier.innerHTML = positionsIcon;
				buttonCashier.appendChild(document.createTextNode(" Historial"));
				buttonCashier.style.display = "flex";
				buttonCashier.style.alignItems = "center";
				buttonCashier.style.gap = "5px";
				buttonCashier.setAttribute("title", "Historial y Posiciones Abiertas");
				buttonCashier.addEventListener("click", function () {
					onChange();
				});

				const buttonPerfil = tvWidget.createButton({
					useTradingViewStyle: false,
					align: "right",
				});
				buttonPerfil.innerHTML = userIcon;
				buttonPerfil.appendChild(document.createTextNode(" Perfil"));
				buttonPerfil.style.display = "flex";
				buttonPerfil.style.alignItems = "center";
				buttonPerfil.style.gap = "5px";
				buttonPerfil.setAttribute("title", "Perfil y Cuentas");
				buttonPerfil.addEventListener("click", function () {
					setShowUserProfilePage(true);
				});

				const buttonWatchList = tvWidget.createButton({
					useTradingViewStyle: false,
					align: "left",
				});
				buttonWatchList.innerHTML = symbolsIcon;
				buttonWatchList.appendChild(document.createTextNode(" Simbolos"));
				buttonWatchList.style.display = "flex";
				buttonWatchList.style.alignItems = "center";
				buttonWatchList.style.gap = "5px";
				buttonWatchList.setAttribute("title", "Abrir Símbolos");
				buttonWatchList.addEventListener("click", function () {
					openSymbols();
				});
			});
		});

		return () => {
			tvWidget.remove();
		};
	};

	return (
		<Box sx={{ display: "flex", height: "100vh", backgroundColor: "#161a25" }}>
			<Box sx={{ flex: 1, display: "flex", flexDirection: "column", width: "75%" }}>
				<div ref={chartContainerRef} className="TVChartContainer" style={{ flexGrow: 1 }} />
				<CashierPage onHide={() => setShowCashierPage(false)} showReportPage={showCashierPage} />
				<UserProfilePage onHide={() => setShowUserProfilePage(false)} showReportPage={showUserProfilePage} isIB={user.role === "IB"} />
				{showPosition && (
					<Box sx={{ height: "25%", borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={value} onChange={(e, newValue) => setValue(newValue)} aria-label="basic tabs example">
							{items.map((item) => (
								<Tab
									key={item.id}
									label={item.title.toLowerCase()}
									id={`simple-tab-${item.id}`}
									aria-controls={`simple-tabpanel-${item.id}`}
									sx={{
										textTransform: "capitalize",
										fontSize: "0.875rem",
									}}
								/>
							))}
						</Tabs>
						{items.map((item) => (
							<div
								role="tabpanel"
								hidden={value !== item.id}
								id={`simple-tabpanel-${item.id}`}
								aria-labelledby={`simple-tab-${item.id}`}
								key={item.id}>
								{value === item.id && <Box sx={{ p: 1, height: "100%", fontSize: "0.875rem" }}>{item.component}</Box>}
							</div>
						))}
					</Box>
				)}
				<MyDraggableComponent />
			</Box>
			{showWatchList && (
				<Box sx={{ width: "15%", padding: "10px", display: "flex", flexDirection: "column" }}>
					<WatchList />
				</Box>
			)}
		</Box>
	);
};
