// src/pages/Register/RegisterPage.jsx

import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { Container, CssBaseline, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const RegisterPage = () => {
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		country: "",
		password: "",
		confirmPassword: "",
		identityFile: null,
	});
	const [isImageUploaded, setIsImageUploaded] = useState(false);
	const [errors, setErrors] = useState({});
	const history = useHistory();

	const handleChange = (e) => {
		const { name, value, files } = e.target;
		if (name === "identityFile") {
			setFormData((prev) => ({ ...prev, [name]: files[0] }));
			setIsImageUploaded(files.length > 0); // Set state based on file upload
		} else {
			setFormData((prev) => ({ ...prev, [name]: value }));
		}
	};

	const validate = () => {
		let tempErrors = {};
		if (!formData.fullName) tempErrors.fullName = "Nombre completo es requerido.";
		if (!formData.email) tempErrors.email = "Correo electrónico es requerido.";
		else if (!/\S+@\S+\.\S+/.test(formData.email)) tempErrors.email = "Correo electrónico no es válido.";
		if (!formData.country) tempErrors.country = "País es requerido.";
		if (!formData.password) {
			tempErrors.password = "Contraseña es requerida.";
		} else if (formData.password.length < 6) {
			tempErrors.password = "La contraseña debe tener al menos 6 caracteres.";
		} else if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/.test(formData.password)) {
			tempErrors.password = "La contraseña debe contener al menos una mayúscula, un número y un símbolo.";
		}
		if (!formData.confirmPassword) tempErrors.confirmPassword = "Por favor confirme su contraseña.";
		else if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Las contraseñas no coinciden.";
		if (!formData.identityFile) tempErrors.identityFile = "Por favor suba una identificación.";

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleRegister = async (e) => {
		e.preventDefault();
		if (!validate()) {
			return; // Si hay errores, no enviar el formulario
		}
		try {
			const formDataObj = new FormData();
			for (const key in formData) {
				formDataObj.append(key, formData[key]);
			}

			const response = await axios.post(`${process.env.REACT_APP_API_URL}/registerT`, formDataObj, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log("REGISTER", response);

			if (response.status === 200) {
				toast.success("Registro exitoso");
				history.push("/login");
			} else {
				toast.error(response.data.message || "Error durante el registro");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Un error ocurrió. Por favor intente de nuevo.");
		}
	};

	return (
		<>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg1.png")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Box
					sx={{
						position: "absolute",
						right: 0,
						top: "50%",
						transform: "translateY(-50%)",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						padding: "20px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					}}>
					<CssBaseline />
					<Container component="main" maxWidth="xs">
						<Box
							sx={{
								marginTop: 8,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<Box
								sx={{
									// position: { xs: "static", md: "absolute" },
									// // top: { md: "-10" },
									// left: { md: "47%" },
									width: "100%",
									maxWidth: "150px",
									zIndex: 1,
								}}>
								<img src="/assets/logo.png" alt="Login" width={"100%"} height="auto" />
							</Box>
							<Typography component="h1" variant="h5" sx={{ color: "#c9a75f" }}>
								Registrarse
							</Typography>
							<Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="fullName"
									label="Nombre Completo"
									name="fullName"
									autoComplete="name"
									autoFocus
									value={formData.fullName}
									onChange={handleChange}
									error={!!errors.fullName}
									helperText={errors.fullName}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Correo Electrónico"
									name="email"
									autoComplete="email"
									value={formData.email}
									onChange={handleChange}
									error={!!errors.email}
									helperText={errors.email}
								/>
								<FormControl fullWidth margin="normal" error={!!errors.country}>
									<InputLabel id="country-label">País</InputLabel>
									<Select
										labelId="country-label"
										id="country"
										name="country"
										value={formData.country}
										onChange={handleChange}
										displayEmpty>
										<MenuItem value="">
											<em>Selecciona tu país</em>
										</MenuItem>
										<MenuItem value="argentina">Argentina</MenuItem>
										<MenuItem value="bolivia">Bolivia</MenuItem>
										<MenuItem value="brasil">Brasil</MenuItem>
										<MenuItem value="chile">Chile</MenuItem>
										<MenuItem value="colombia">Colombia</MenuItem>
										<MenuItem value="costaRica">Costa Rica</MenuItem>
										<MenuItem value="cuba">Cuba</MenuItem>
										<MenuItem value="ecuador">Ecuador</MenuItem>
										<MenuItem value="salvador">El Salvador</MenuItem>
										<MenuItem value="guatemala">Guatemala</MenuItem>
										<MenuItem value="haiti">Haití</MenuItem>
										<MenuItem value="honduras">Honduras</MenuItem>
										<MenuItem value="mexico">México</MenuItem>
										<MenuItem value="nicaragua">Nicaragua</MenuItem>
										<MenuItem value="panama">Panamá</MenuItem>
										<MenuItem value="paraguay">Paraguay</MenuItem>
										<MenuItem value="peru">Perú</MenuItem>
										<MenuItem value="republicaDominicana">República Dominicana</MenuItem>
										<MenuItem value="uruguay">Uruguay</MenuItem>
										<MenuItem value="venezuela">Venezuela</MenuItem>
									</Select>
									{errors.country && <FormHelperText>{errors.country}</FormHelperText>}
								</FormControl>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="new-password"
									value={formData.password}
									onChange={handleChange}
									error={!!errors.password}
									helperText={errors.password || "Debe contener al menos 6 caracteres, una mayúscula, un número y un símbolo"}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="confirmPassword"
									label="Verificar Contraseña"
									type="password"
									id="confirmPassword"
									autoComplete="new-password"
									value={formData.confirmPassword}
									onChange={handleChange}
									error={!!errors.confirmPassword}
									helperText={errors.confirmPassword}
								/>
								<input
									accept="image/*"
									style={{ display: "none" }}
									id="raised-button-file"
									type="file"
									name="identityFile"
									onChange={handleChange}
								/>
								<label htmlFor="raised-button-file">
									<Button
										fullWidth
										variant="outlined"
										component="span"
										sx={{
											mt: 2,
											mb: 2,
											backgroundColor: isImageUploaded ? "#c9a75f" : "initial", // Change color if image uploaded
											color: isImageUploaded ? "#fff" : "#fff",
											"&:hover": {
												backgroundColor: isImageUploaded ? "#c9a75f" : "initial",
											},
										}}>
										{isImageUploaded ? "Imagen Cargada" : "Subir Identificación"}
									</Button>
								</label>
								{errors.identityFile && <Typography color="error">{errors.identityFile}</Typography>}
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 3,
										mb: 2,
										backgroundColor: "#c9a75f",
										color: "#000",
										"&:hover": {
											backgroundColor: "#f6d6a3",
										},
									}}>
									Registrar
								</Button>
								<Typography align="center">
									<Link to="/" style={{ color: "#fff" }}>
										¿Ya tienes cuenta? Inicia sesión
									</Link>
								</Typography>
							</Box>
						</Box>
					</Container>
				</Box>
			</Box>
		</>
	);
};

export default RegisterPage;
