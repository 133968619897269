import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useRef, useCallback } from "react";
import { IconButton, Modal, Box, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/CancelPresentation";
import CloseIcon from "@mui/icons-material/EditNote";
import socket from "../../../../socket/Socket.js";
import { store } from "../../../../store/store.js";
import "../styles/MuiDataGrid-footerContainer.css";
import { toast } from "react-toastify";

export function OpeningPositionsTable() {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const derivWS = store((state) => state.derivWS);
	const balance = store((state) => state.balance);
	const openPositions = store((state) => state.openPositions);

	const [reports, setReports] = useState([]);
	const [equity, setEquity] = useState(0);
	const reportsRef = useRef([]);
	const subscriptionsRef = useRef({});
	const [popupOpen, setPopupOpen] = useState(false);
	const [popupType, setPopupType] = useState("");
	const [popupValue, setPopupValue] = useState("");
	const [selectedTicketId, setSelectedTicketId] = useState(null);
	const [currentPositions, setCurrentPositions] = useState(new Map());
	const [openModal, setOpenModal] = useState(false);
	const [closeLots, setCloseLots] = useState("");
	const [currentRow, setCurrentRow] = useState(null);

	const handlePartialClose = (row) => {
		setCurrentRow(row);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setCloseLots("");
		setCurrentRow(null);
	};

	const handleSubmit = () => {
		if (currentRow && closeLots) {
			if (Number(currentRow.lots) < Number(closeLots)) {
				toast.error("No puedes cerrar más lotes que lo que tienes abiertos");
				return;
			}
			const positionData = { ...currentRow };
			positionData.closeLots = closeLots;
			socket.emit("closeLine", positionData);
			handleCloseModal();
		}
	};

	const columns = [
		{
			field: "entryDate",
			headerName: "Fecha Inicio",
			flex: 0.4,
		},
		{
			field: "type",
			headerName: "Tipo",
			flex: 0.3,
		},
		{
			field: "lots",
			headerName: "Lotes",
			flex: 0.2,
		},
		// {
		// 	field: "symbol",
		// 	headerName: "Símbolo",
		// 	flex: 0.3,
		// },
		{
			field: "symbolName",
			headerName: "Nombre",
			flex: 0.5,
		},
		{
			field: "stopLoss",
			headerName: "Stop Loss",
			flex: 0.3,
		},
		{
			field: "takeProfit",
			headerName: "Take Profit",
			flex: 0.3,
		},
		{
			field: "entryPrice",
			headerName: "Precio Entrada",
			flex: 0.3,
		},
		{
			field: "profit",
			headerName: "Profit",
			flex: 0.3,
		},
		{
			field: "actions",
			headerName: "Acciones",
			flex: 0.3,
		},
	];

	const calculateProfit = useCallback(({ symbol, type, entryPrice, price, pipValue, pip, lots, commision }) => {
		if (type !== "Buy" && type !== "Sell") return 0;

		if (type === "Buy") {
			let profit = ((Number(price) - Number(entryPrice)) / pip) * pipValue * lots - Number(commision);
			return profit;
		} else {
			let profit = ((Number(entryPrice) - Number(price)) / pip) * pipValue * lots - Number(commision);
			return profit;
		}
	}, []);

	const calculateEquity = useCallback(
		(reports) => {
			const totalProfit = reports.reduce((sum, position) => {
				if (position.type === "Buy" || position.type === "Sell") {
					return sum + Number(position.profit);
				}
				return sum;
			}, 0);

			const equity = Number(balance) + Number(totalProfit);
			setEquity(equity);
		},
		[balance]
	);

	const getTicks = useCallback(
		async (data) => {
			return new Promise((resolve, reject) => {
				const handleData = (event) => {
					const msgData = JSON.parse(event.data);
					const msgType = msgData.msg_type;

					if (msgType === "tick" && msgData.tick) {
						const { id, symbol, quote } = msgData.tick;
						// setTicks((prevTicks) => ({
						// 	...prevTicks,
						// 	[symbol]: quote,
						// }));

						setCurrentPositions((prevPositions) => {
							const updatedPositions = new Map(prevPositions);
							updatedPositions.set(symbol, id);
							return updatedPositions;
						});

						setReports((prevReports) => {
							const newReports = prevReports.map((report) => {
								const activeLine = msgData.tick.symbol === report.symbol;
								const price = msgData.tick.quote;

								const profit =
									activeLine && (report.type === "Buy" || report.type === "Sell")
										? calculateProfit({ ...report, price })
										: report.profit;

								return activeLine
									? {
											...report,
											price,
											profit: typeof profit === "number" ? profit.toFixed(2) : profit,
									  }
									: report;
							});
							calculateEquity(newReports);

							return newReports;
						});

						resolve(data);
					}
				};

				const handleError = (error) => {
					derivWS.removeEventListener("message", handleData);
					onErrorCallback(error);
					reject(error);
				};

				derivWS.addEventListener("message", handleData);
				derivWS.addEventListener("error", handleError);

				for (let index = 0; index < data.length; index++) {
					const element = data[index];
					const sendMessage = JSON.stringify({
						ticks: element.symbol,
						subscribe: 1,
					});
					derivWS.send(sendMessage);
				}
			});
		}
		// [calculateProfit, derivWS, calculateEquity]
	);

	useEffect(() => {
		updateEquity();
	}, []);

	useEffect(() => {
		if (openPositions) {
			// socket.on("allPositions", (data) => {
			// console.log("OPEN POSITIONS", openPositions);
			if (openPositions === null || openPositions.length === 0) {
				// updateEquity();
				currentPositions.forEach((subscriptionId, symbol) => {
					derivWS.send(JSON.stringify({ forget: subscriptionId }));
				});
				setCurrentPositions(new Map());
				setReports([]);
				return;
			}

			const filteredPositions = openPositions;
			const activeSymbols = new Set(filteredPositions.map((p) => p.symbol));
			currentPositions.forEach((subscriptionId, symbol) => {
				if (!activeSymbols.has(symbol)) {
					derivWS.send(JSON.stringify({ forget: subscriptionId }));
					// updateEquity();
					setCurrentPositions((prevPositions) => {
						const updatedPositions = new Map(prevPositions);
						updatedPositions.delete(symbol);
						return updatedPositions;
					});
				}
			});

			const formatDate = (dateString) => {
				const date = new Date(dateString);
				const day = String(date.getDate()).padStart(2, "0");
				const month = String(date.getMonth() + 1).padStart(2, "0");
				const year = String(date.getFullYear()).slice(-2);
				const hours = String(date.getHours()).padStart(2, "0");
				const minutes = String(date.getMinutes()).padStart(2, "0");
				return `${day}/${month}/${year} ${hours}:${minutes}`;
			};

			getTicks(filteredPositions);
			filteredPositions.forEach((position) => {
				if (position.createdAt) {
					position.entryDate = formatDate(position.createdAt);
				}
			});

			reportsRef.current = filteredPositions.reverse();
			setReports(reportsRef.current);
			// });
		}
		// return () => {
		// 	if (socket) {
		// 		socket.off("allPositions");
		// 	}
		// };
	}, [openPositions]);

	function updateEquity() {
		setTimeout(() => {
			setEquity(Number(balance));
		}, 1000);
	}

	const handleCellClick = (type, value, ticketId) => {
		setPopupType(type);
		setPopupValue(value);
		setSelectedTicketId(ticketId);
		setPopupOpen(true);
	};

	const handleUpdate = () => {
		const sA = JSON.parse(localStorage.getItem("selectedAccount"));
		if (popupType === "SL") {
			updateStopLoss(selectedTicketId, popupValue, sA);
		} else if (popupType === "TP") {
			updateTakeProfit(selectedTicketId, popupValue, sA);
		}
		setPopupOpen(false);
	};

	function updateStopLoss(ticketId, price, sA) {
		socket.emit("modifyPosition", {
			accountId: sA.accountId,
			userId: sA.userId,
			ticketId: ticketId,
			priceSL: price,
			modify: "SL",
		});
	}

	function updateTakeProfit(ticketId, price, sA) {
		socket.emit("modifyPosition", {
			accountId: sA.accountId,
			userId: sA.userId,
			ticketId: ticketId,
			priceTP: price,
			modify: "TP",
		});
	}

	const handleSymbolClick = async (row) => {
		console.log("SYMBOL CLICK", row);
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		console.log("SELECTED ACCOUNT", selectedAccount);
		const tvWidget = store.getState().chart;
		// const symbolReady = await setSymbolStorage(row);
		if (tvWidget && tvWidget.chart) {
			await tvWidget.chart().setSymbol(row.symbolName, tvWidget.activeChart().resolution());
			let allPos = {
				userId: selectedAccount.userId,
				accountId: selectedAccount.accountId,
			};
			console.log("ALL POS", allPos);
			socket.emit("allLines", allPos);
		}
	};

	return (
		<>
			<DataGrid
				columnHeaderHeight={30}
				rowHeight={30}
				sx={{
					maxHeight: `${window.innerHeight * 0.18}px`,
					"& .total-row": {
						backgroundColor: "#71797E",
						fontWeight: "bold",
						"& .MuiDataGrid-cell": {
							fontSize: 14,
						},
					},
					"& .MuiDataGrid-cell": {
						fontSize: 14,
					},
				}}
				hideFooter
				columns={columns.map((column) => ({
					...column,
					renderHeader: () => <strong>{column.headerName}</strong>,
					renderCell: (params) => {
						if (params.row.ticketId === "total") {
							if (column.field === "profit") {
								const profit = parseFloat(params.value);
								const color = profit > 0 ? "#2eb62e" : profit < 0 ? "red" : "inherit";
								return <span style={{ color, fontWeight: "bold", fontSize: 16 }}>{params.value}</span>;
							}
							if (column.field === "actions") {
								return null;
							}
						}

						if (column.field === "symbolName") {
							return (
								<div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleSymbolClick(params.row)}>
									{params.value.toString().replace(/^(frx|cry)/, "")}
								</div>
							);
						}

						if (column.field === "profit") {
							const profit = parseFloat(params.value);
							const color = profit > 0 ? "#2eb62e" : profit < 0 ? "red" : "inherit";
							return <span style={{ color }}>{params.value}</span>;
						}

						if (column.field === "stopLoss" || column.field === "takeProfit") {
							return (
								<div onClick={() => handleCellClick(column.field === "stopLoss" ? "SL" : "TP", params.value, params.row.ticketId)}>
									{params.value}
								</div>
							);
						}

						if (column.field === "actions") {
							const handleDelete = () => {
								params.row.openPos = true;
								socket.emit("closeLine", params.row);
							};

							return (
								<div style={{ display: "flex", justifyContent: "space-around" }}>
									<Tooltip title="Cierre Parcial">
										<IconButton onClick={() => handlePartialClose(params.row)} color="error" style={{ fontSize: 8 }}>
											<CloseIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Cerrar">
										<IconButton onClick={handleDelete} color="error" style={{ fontSize: 8 }}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>{" "}
								</div>
							);
						}

						return params.value;
					},
				}))}
				rows={[
					...reports,
					{
						ticketId: "total",
						entryDate: +isNaN(balance) ? "Balance: $" + 0 : "Balance: $" + balance,
						type: isNaN(equity) ? "Equity: $" + 0 : "Equity: $" + equity,
						lots: "",
						symbolName: "",
						stopLoss: "",
						takeProfit: "",
						entryPrice: "Total Profit",
						profit: reports.reduce((acc, report) => acc + parseFloat(report.profit || 0), 0).toFixed(2),
					},
				]}
				// onRowClick={handleRowClick}
				getRowId={(row) => row.ticketId}
				getRowClassName={(params) => (params.row.ticketId === "total" ? "total-row" : "")}
			/>

			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 300,
						bgcolor: "background.paper",
						borderRadius: "15px",
						boxShadow: 24,
						p: 4,
					}}>
					<h2>Cierre Parcial</h2>
					{/* <Typography variant="subtitle1" gutterBottom>
						Lotaje: {currentRow.lots ? currentRow.lots : 0}
						<br />
						Símbolo: {currentRow.symbol ? currentRow.symbol : 0}
						<br />
						Tipo: {currentRow.type ? currentRow.type : 0}
					</Typography> */}
					<TextField
						label="Lotaje a Cerrar"
						variant="outlined"
						value={closeLots}
						onChange={(e) => {
							const value = e.target.value.replace(",", ".");
							if (/^\d*\.?\d*$/.test(value)) {
								setCloseLots(value);
							}
						}}
						fullWidth
						margin="normal"
					/>
					<Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
						Cerrar Parcial
					</Button>
				</Box>
			</Modal>

			<Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
				<DialogTitle>{popupType === "SL" ? "Update Stop Loss" : "Update Take Profit"}</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						label="Price"
						type="number"
						fullWidth
						value={popupValue}
						onChange={(e) => setPopupValue(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setPopupOpen(false)}>Cancel</Button>
					<Button onClick={handleUpdate}>Update</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
