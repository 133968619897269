import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
import { store } from "../../../../store/store";
import socket from "../../../../socket/Socket.js";
import axios from "axios";

export const AccountsPage = ({ onHide }) => {
	const initialActiveAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const [activeAccount, setActiveAccount] = useState(initialActiveAccount);
	const [accounts, setAccounts] = useState([]);
	const [isVisible, setIsVisible] = useState(true);

	const handleVisibilityChange = () => {
		if (document.visibilityState === "visible") {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (isVisible) {
			getAccounts();
		}
	}, [isVisible]);

	const getAccounts = async () => {
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/getList2`, {
				table: "accounts",
				userId: initialActiveAccount.userId,
			});
			localStorage.setItem("accounts", JSON.stringify(response.data));
			setAccounts(response.data);
		} catch (error) {
			if (error.message === "Network Error") {
				toast.error("Error de conexión. Por favor, verifica tu conexión a internet e intenta nuevamente.");
			} else {
				toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
			}
		}
	};

	const handleAccountSelection = (account) => {
		if (account.status === "0" || Number(account.balance) <= 0) {
			toast.error("Esta cuenta está desactivada o no tiene fondos");
			return;
		}

		const combinedAccount = {
			...activeAccount, // Mantén los datos adicionales de selectedAccount
			...account, // Sobrescribe con los datos de account
			accountId: account.id, // Asegúrate de que accountId esté presente
		};
		delete combinedAccount.id;

		const setBalance = store.getState().setBalance;
		setBalance(Number(combinedAccount.balance).toFixed(2));
		delete combinedAccount.balance;

		const data = { userId: combinedAccount.userId, accountId: combinedAccount.accountId };
		socket.emit("allLines", data);

		const setSelectedAccount = store.getState().setSelectedAccount;
		setSelectedAccount(combinedAccount);
		localStorage.setItem("selectedAccount", JSON.stringify(combinedAccount));
		setActiveAccount(combinedAccount);
		onHide(false);
	};

	return (
		<div>
			<Typography sx={{ fontSize: 14, p: 1 }} color="text.secondary" gutterBottom>
				Cuentas
			</Typography>
			<Grid container spacing={1}>
				{accounts &&
					accounts.map((account, index) => {
						const isActiveAccount = activeAccount?.accountId === account.id;
						return (
							<Grid item xs={6} sm={6} md={6} lg={6} xxl={6} key={index}>
								<div className="cart-container" style={{ maxWidth: 345 }}>
									<img
										src="/assets/trofeo.png"
										alt="Tournament"
										style={{ width: "80%", marginBottom: "10px", borderRadius: "50%" }}
									/>
									<h2 className="cart-container-title">${account.balance}</h2>
									<p className="container-description cart-capitalize">{account.type}</p>
									<Button
										onClick={() => handleAccountSelection(account)}
										sx={{
											backgroundColor: isActiveAccount ? "#c9a75f" : "#f6d6a3",
											color: isActiveAccount ? "#000" : "#000",
											border: isActiveAccount ? "1px solid #c9a75f" : "1px solid #ccc",
											borderRadius: "15px",
											fontWeight: "bold",
											padding: "10px",
											cursor: "pointer",
											"&:hover": {
												backgroundColor: isActiveAccount ? "#c9a75f" : "#f6d6a3",
											},
										}}>
										{isActiveAccount ? "Activa" : "Seleccionar"}
									</Button>
									C-{account.id}
								</div>
							</Grid>
						);
					})}
			</Grid>
		</div>
	);
};
